import bootstrapper from '../../../bootstrapper';
import page from './page';
import track from './track';
import { isFunction } from '../../../utils/isType';
import isMiddlewareEnabled from '../../utils/isMiddlewareEnabled';

const analytics = {
  page,
  track,
};

const facebookPixel = methodName => next => (object) => {
  const { options } = object || {};
  if (!bootstrapper.facebookPixelEnabled || !isMiddlewareEnabled(options, 'Facebook Pixel')) {
    return next(object);
  }

  const method = analytics[methodName];
  if (isFunction(method)) {
    method(object);
  }

  return next(object);
};

export default facebookPixel;
