import { isFunction } from '../../../utils/isType';

const logger = methodName => next => (...args) => {
  let formattedArgs = '';
  args.forEach((arg) => {
    if (isFunction(arg)) {
      formattedArgs += `\tƒ ${arg.name || 'anonymous'},\n`;
    } else {
      formattedArgs += `\t${JSON.stringify(arg)},\n`;
    }
  });

  const output = `emeritusAnalytics.${methodName}(\n${formattedArgs} )`;

  console.groupCollapsed(`Analytics event: ${methodName}`) // eslint-disable-line
  console.info(`%c ${output}`, 'color: #59a2ea') // eslint-disable-line
  console.groupEnd() // eslint-disable-line

  return next(...args);
};

export default logger;
