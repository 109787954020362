import { isFunction, isObject, isString } from '../../utils/isType';
import bootstrapper from '../../bootstrapper';

const canonical = () => {
  const tags = document.getElementsByTagName('link');
  for (let i = 0; i < tags.length; i += 1) {
    const tag = tags[i];
    if (tag.getAttribute('rel') === 'canonical') {
      return tag.getAttribute('href');
    }
  }
  return undefined;
};

const canonicalPath = () => {
  const canon = canonical();
  if (canon) {
    return new URL(canon).pathname;
  }

  return window.location.pathname;
};

const canonicalUrl = (search) => {
  const canon = canonical();
  if (canon) {
    return canon.includes('?') ? canon : canon + search;
  }
  const url = window.location.href;
  const i = url.indexOf('#');
  return i === -1 ? url : url.slice(0, i);
};

const pageDefaults = () => ({
  path: canonicalPath(),
  referrer: document.referrer,
  search: window.location.search,
  title: document.title,
  url: canonicalUrl(window.location.search),
});

// Parameters:
// String category (optional)
// String name (optional)
// Object properties (optional)
// Object options (optional)
// Function callback (optional)
export default (...args) => {
  let category;
  let name;
  let properties = {};
  let options = {};
  const [arg0, arg1, arg2, arg3] = args;

  if (isString(arg0)) {
    if (isString(arg1)) {
      category = arg0;
      name = arg1;
    } else {
      name = arg0;
    }
  }

  if (isObject(arg0)) {
    properties = arg0;
    if (isObject(arg1)) {
      options = arg1;
    }
  } else if (isObject(arg1)) {
    properties = arg1;
    if (isObject(arg2)) {
      options = arg2;
    }
  } else if (isObject(arg2)) {
    properties = arg2;
    if (isObject(arg3)) {
      options = arg3;
    }
  }

  const callback = args.find(arg => isFunction(arg));
  const defaultProperties = Object.assign(
    pageDefaults(),
    bootstrapper.activeExperiments || {},
  );
  const defaultOptions = {
    integrations: {
      'Facebook Pixel': false,
    },
  };

  properties = Object.assign({}, defaultProperties, properties);
  options = Object.assign({}, defaultOptions, options);

  if (name) {
    properties.name = name;
  }
  if (category) {
    properties.category = category;
  }

  return {
    category, name, properties, options, callback,
  };
};
