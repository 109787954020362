/* eslint-disable */
import bootstrapper from '../../../bootstrapper';

const {
  facebookPixelEnabled,
  facebookPixelId: mainPixel,
  programFacebookPixelId: programPixel,
  ccpaOptOut,
} = bootstrapper;

const mainPixelIsEnabled = () => {
  return facebookPixelEnabled && mainPixel;
}

const programPixelIsEnabled = () => {
  return facebookPixelEnabled && programPixel;
}

export default () => {
  if (mainPixelIsEnabled()) {
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    if(ccpaOptOut) {
      fbq('dataProcessingOptions', ['LDU'], 0, 0);
    }
    else {
      fbq('dataProcessingOptions', []);
    }
    fbq('init', `${mainPixel}`);
    window.fbq = fbq;
  }

  if (programPixelIsEnabled()) {
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    if(ccpaOptOut) {
      fbq('dataProcessingOptions', ['LDU'], 0, 0);
    }
    else {
      fbq('dataProcessingOptions', []);
    }
    fbq('init', `${programPixel}`);
    window.fbq = fbq;
  }
}

/* eslint-enable */
