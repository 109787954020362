import { isFunction } from '../../../utils/isType';
import isMiddlewareEnabled from '../../utils/isMiddlewareEnabled';
import bootstrapper from '../../../bootstrapper';

export const track = (data) => {
  if (!window.emeritusPixel) {
    return;
  }

  const defaultProperties = {
    landing_page_template_id: bootstrapper.landingPageTemplateId,
  };
  const properties = Object.assign({}, data.properties, defaultProperties);
  const { event } = data;

  window.emeritusPixel.send(event, properties);
};

const analytics = {
  track,
};

export const emeritusPixelMiddleware = methodName => next => (object) => {
  const { options } = object || {};

  if (
    !bootstrapper.openpixelEnabled
    || !isMiddlewareEnabled(options, 'Openpixel')
  ) {
    return next(object);
  }

  const method = analytics[methodName];
  if (isFunction(method)) {
    method(object);
  }

  return next(object);
};

export const initEmeritusPixel = (sendPageViewEvent = false) => {
  if (bootstrapper.openpixelEnabled) {
    if (window.emeritusPixel) {
      // eslint-disable-next-line
      console.error(new Error('tried to load emeritus pixel more than once'));

      return;
    }

    const pixelScript = document.createElement('script');
    if (bootstrapper.assetHost) {
      pixelScript.src = `https://${bootstrapper.assetHost}/emeritus-pixel.min.js`;
    } else {
      pixelScript.src = 'https://d2w1vb445pcruu.cloudfront.net/emeritus-pixel.min.js';
    }
    pixelScript.async = 1;

    pixelScript.addEventListener('load', () => {
      window.emeritusPixel = new window.epix.Pixel('/data_pixel');

      if (sendPageViewEvent) {
        window.emeritusPixel.send('page view - client', {
          active_experiments: bootstrapper.activeExperiments,
          landing_page_template_id: bootstrapper.landingPageTemplateId,
          microsite_id: bootstrapper.micrositeId,
        });
      }
    });

    document.head.appendChild(pixelScript);
  }
};
