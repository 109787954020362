import applyMiddlewares from '../utils/applyMiddlewares';
import normalizeIdentify from './utils/normalizeIdentify';
import normalizePage from './utils/normalizePage';
import normalizeTrack from './utils/normalizeTrack';

let middlewareChain;

const analytics = {
  init: ({ list, initialize }) => {
    middlewareChain = {
      identify: applyMiddlewares('identify', () => {}, list),
      page: applyMiddlewares('page', () => {}, list),
      track: applyMiddlewares('track', () => {}, list),
      reset: applyMiddlewares('reset', () => {}, list),
    };

    initialize();
  },
  identify: (...args) => {
    const { userId, traits, callback } = normalizeIdentify(...args);
    middlewareChain.identify({ userId, traits });

    if (callback) {
      callback();
    }
  },
  page: (...args) => {
    const {
      category, name, properties, options, callback,
    } = normalizePage(...args);
    middlewareChain.page({
      category, name, properties, options,
    });

    if (callback) {
      callback();
    }
  },
  track: (...args) => {
    const {
      event, properties, options, callback,
    } = normalizeTrack(...args);
    middlewareChain.track({
      event, properties, options, callback,
    });
    if (callback) {
      callback();
    }
  },
  reset: () => {
    middlewareChain.reset();
  },
};

export default analytics;
