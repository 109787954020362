import { isBoolean } from '../../utils/isType';

// TODO: this logic is a bit confusing, it might need some changes
const isMiddlewareEnabled = (options, middlewareName) => {
  if (!options || !options.integrations) {
    return true;
  }

  let allIntegrations = options.integrations.All;
  if (!isBoolean(allIntegrations)) {
    allIntegrations = true;
  }

  if (allIntegrations) {
    return options.integrations[middlewareName] !== false;
  }
  return options.integrations[middlewareName] === true;
};

export default isMiddlewareEnabled;
