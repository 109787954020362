import bootstrapper from '../../../bootstrapper';

export default (object) => {
  const { event, properties: { value, currency } = {} } = object;
  const { googleAdsPixelConversionId, googleAdsPixelConversionLabel } = bootstrapper;

  window.gtag(
    'event',
    event,
    {
      send_to: `${googleAdsPixelConversionId}/${googleAdsPixelConversionLabel}`,
      value,
      currency,
    },
  );
};
