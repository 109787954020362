/* eslint-disable */
import bootstrapper from '../../../bootstrapper';

export default () => {
  const {
    bingPixelEnabled,
    bingPixelTagId,
  } = bootstrapper;

  if (bingPixelEnabled && bingPixelTagId) {
    (function (w, d, t, r, u) {
      let f;
      let n;
      let i; w[u] = w[u] || [], f = function () { const o = { ti: bingPixelTagId }; o.q = w[u], w[u] = new UET(o), w[u].push('pageLoad'); }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () { const s = this.readyState; s && s !== 'loaded' && s !== 'complete' || (f(), n.onload = n.onreadystatechange = null); }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i);
    }(window, document, 'script', ' //bat.bing.com/bat.js', 'uetq'));
    window.uetq = window.uetq || [];
  }
};
