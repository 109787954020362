const isFunction = elem => typeof elem === 'function';
const isObject = elem => typeof elem === 'object';
const isString = elem => typeof elem === 'string';
const isBoolean = elem => typeof elem === 'boolean';

export {
  isFunction,
  isObject,
  isString,
  isBoolean,
};
