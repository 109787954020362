import { isFunction, isObject } from '../../utils/isType';
import bootstrapper from '../../bootstrapper';

// Parameters:
// String event (required)
// Object properties (optional)
// Object options (optional)
// Function callback (optional)
export default (...args) => {
  let properties = {};
  let options = {};
  const [event, arg1, arg2] = args;

  if (isObject(arg1)) {
    properties = arg1;
  }

  if (isObject(arg2)) {
    options = arg2;
  }

  const callback = args.find(arg => isFunction(arg));
  const defaultProperties = bootstrapper.activeExperiments || {};
  const defaultOptions = {
    integrations: {
      'Facebook Pixel': false,
    },
  };

  properties = Object.assign({}, defaultProperties, properties);
  options = Object.assign({}, defaultOptions, options);

  return {
    event, properties, options, callback,
  };
};
