const applyMiddlewares = (methodName, method, middlewares) => {
  let wrappedMethod = method;
  const reversedMiddlewares = middlewares.slice().reverse();

  reversedMiddlewares.forEach((middleware) => {
    wrappedMethod = middleware(methodName)(wrappedMethod);
  });

  return wrappedMethod;
};

export default applyMiddlewares;
