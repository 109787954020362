import bootstrapper from '../../../bootstrapper';

export default () => {
  const {
    googleAdsPixelEnabled,
    googleAdsPixelConversionId,
    googleAdsPixelConversionLabel,
  } = bootstrapper;

  if (
    googleAdsPixelEnabled
    && googleAdsPixelConversionId
    && googleAdsPixelConversionLabel
  ) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAdsPixelConversionId}`;
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag = window.gtag || ((...args) => {
      window.dataLayer.push(args);
    });

    window.gtag('js', new Date());
    window.gtag('config', googleAdsPixelConversionId);
  }
};
