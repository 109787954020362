import bootstrapper from '../../../bootstrapper';
import identify from './identify';
import page from './page';
import track from './track';
import reset from './reset';
import { isFunction } from '../../../utils/isType';
import isMiddlewareEnabled from '../../utils/isMiddlewareEnabled';

const analytics = {
  identify,
  page,
  track,
  reset,
};

const amplitude = methodName => next => (object) => {
  const { options } = object || {};

  if (!bootstrapper.amplitudeEnabled || !isMiddlewareEnabled(options, 'Amplitude')) {
    return next(object);
  }

  const method = analytics[methodName];
  if (isFunction(method)) {
    method(object);
  }

  return next(object);
};

export default amplitude;
