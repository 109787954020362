export default (object) => {
  const { event, properties, options = {} } = object;
  const { fbPixel } = options;

  if (fbPixel) {
    window.fbq('trackSingle', fbPixel.toString(), event, properties);
  } else {
    window.fbq('track', event, properties);
  }
};
