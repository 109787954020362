export default (object) => {
  const { userId, traits } = object;
  if (userId) {
    window.amplitude.getInstance().setUserId(userId);
  }

  const amplitudeIdentify = new window.amplitude.Identify();

  Object.keys(traits).forEach((trait) => {
    amplitudeIdentify.set(trait, traits[trait]);
  });

  window.amplitude.getInstance().identify(amplitudeIdentify);
};
