import bootstrapper from '../../bootstrapper';
import amplitude from './amplitude';
import initAmplitude from './amplitude/initializer';
import facebookPixel from './facebookPixel';
import initFacebookPixel from './facebookPixel/initializer';
import ghostInspector from './ghostInspector';
import initGhostInspector from './ghostInspector/initializer';
import logger from './logger';
import initGoogleAdsPixel from './googleAdsPixel/initializer';
import googleAdsPixel from './googleAdsPixel';
import initLinkedInPixel from './linkedInPixel/initializer';
import initBingPixel from './bingPixel/initializer';
import bingPixel from './bingPixel';
import { emeritusPixelMiddleware, initEmeritusPixel } from './emeritusPixel';

let middlewares;

switch (bootstrapper.appEnv) {
  case 'test': middlewares = []; break;
  case 'development': middlewares = [logger, emeritusPixelMiddleware]; break;
  case 'review_app': middlewares = [logger, amplitude, facebookPixel, ghostInspector, googleAdsPixel, bingPixel, emeritusPixelMiddleware]; break;
  case 'staging': middlewares = [logger, amplitude, facebookPixel, ghostInspector, googleAdsPixel, bingPixel, emeritusPixelMiddleware]; break;
  case 'production': middlewares = [amplitude, facebookPixel, googleAdsPixel, bingPixel, emeritusPixelMiddleware]; break;
  default: middlewares = []; break;
}

export default {
  list: middlewares,
  initialize: () => {
    // TODO: initialize middlewares only if they are set in the middlewares variable (?)
    initEmeritusPixel();
    initLinkedInPixel();
    initGoogleAdsPixel();
    initFacebookPixel();
    initBingPixel();
    initGhostInspector();
    initAmplitude();
  },
};
