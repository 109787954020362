import bootstrapper from '../../../bootstrapper';
import track from './track';
import { isFunction } from '../../../utils/isType';
import isMiddlewareEnabled from '../../utils/isMiddlewareEnabled';

const analytics = {
  track,
};

const googleAdsConversionsPixel = methodName => next => (object) => {
  const { options } = object || {};
  const {
    googleAdsPixelEnabled,
    googleAdsPixelConversionId,
    googleAdsPixelConversionLabel,
  } = bootstrapper;

  if (
    !googleAdsPixelEnabled
    || !isMiddlewareEnabled(options, 'Google Ads Pixel')
    || !googleAdsPixelConversionId
    || !googleAdsPixelConversionLabel
  ) {
    return next(object);
  }

  const method = analytics[methodName];
  if (isFunction(method)) {
    method(object);
  }

  return next(object);
};

export default googleAdsConversionsPixel;
