const ghostInspector = methodName => next => (...args) => {
  const event = Array.prototype.slice.call(args);
  event.unshift(methodName);

  if (window.self === window.top) {
    window.GhostInspectorEvents.push(event);
  } else {
    window.top.postMessage({ type: 'ghostInspectorEvent', event }, '*');
  }

  return next(...args);
};

export default ghostInspector;
