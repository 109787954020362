import track from './track';

const sendReferrer = () => {
  const { referrer } = document;

  if (!referrer || referrer.length === 0) {
    return;
  }

  const identify = new window.amplitude.Identify();

  identify.setOnce('initial_referrer', referrer);
  identify.set('referrer', referrer);

  const parts = referrer.split('/');
  if (parts.length >= 3) {
    const referringDomain = parts[2];
    identify.setOnce('initial_referring_domain', referringDomain);
    identify.set('referring_domain', referringDomain);
  }

  window.amplitude.getInstance().identify(identify);
};

const trackPageEvent = (object, name) => {
  const event = name ? `Viewed ${name} Page` : 'Loaded a Page';
  return Object.assign({}, object, { event });
};

const defaultOptions = {
  trackReferrer: false,
  trackAllPages: false,
  trackCategorizedPages: true,
  trackNamedPages: true,
};

export default (object) => {
  const {
    category, name, options,
  } = object;
  const opts = Object.assign({}, defaultOptions, options);
  const fullName = name && category ? `${category} ${name}` : name;

  if (opts.trackReferrer) {
    sendReferrer();
  }

  if (opts.trackAllPages) {
    track(trackPageEvent(object));
  }

  if (category && opts.trackCategorizedPages) {
    track(trackPageEvent(object, category));
  }

  if (fullName && opts.trackNamedPages) {
    track(trackPageEvent(object, fullName));
  }
};
