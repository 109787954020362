import bootstrapper from '../../../bootstrapper';
import track from './track';
import { isFunction } from '../../../utils/isType';
import isMiddlewareEnabled from '../../utils/isMiddlewareEnabled';

const analytics = {
  track,
};

const bingPixel = methodName => next => (object) => {
  const { options } = object || {};
  const {
    bingPixelEnabled,
    bingPixelTagId,
  } = bootstrapper;

  if (
    !bingPixelEnabled
    || !isMiddlewareEnabled(options, 'Bing Pixel')
    || !bingPixelTagId
  ) {
    return next(object);
  }

  const method = analytics[methodName];
  if (isFunction(method)) {
    method(object);
  }

  return next(object);
};

export default bingPixel;
