import { isFunction, isObject, isString } from '../../utils/isType';

// Parameters:
// String userId (optional)
// Object traits (optional)
// Object options (optional)
// Function callback (optional)
export default (...args) => {
  let userId;
  let traits = {};
  let options = {};
  const [arg0, arg1, arg2] = args;

  if (isString(arg0)) {
    userId = arg0;
  }

  if (isObject(arg0)) {
    traits = arg0;
    if (isObject(arg1)) {
      options = arg1;
    }
  } else if (isObject(arg1)) {
    traits = arg1;
    if (isObject(arg2)) {
      options = arg2;
    }
  }

  const callback = args.find(arg => isFunction(arg));
  const defaultOptions = {
    integrations: {
      'Facebook Pixel': false,
    },
  };

  traits = Object.assign({}, traits);
  options = Object.assign(defaultOptions, options);

  return {
    userId, traits, options, callback,
  };
};
