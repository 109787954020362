/* eslint-disable */
import bootstrapper from '../../../bootstrapper';

const {
  linkedInPixelEnabled,
  linkedInPixelPartnerId,
} = bootstrapper;

export default () => {
  if (linkedInPixelEnabled && linkedInPixelPartnerId) {
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(linkedInPixelPartnerId);

    (function(){
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })();

    const url = `https://px.ads.linkedin.com/collect/?pid=${linkedInPixelPartnerId}&fmt=gif`;
    const img = document.createElement('img');
    img.alt = '';
    img.height = 1;
    img.width = 1;
    img.src = url;
  }
}
/* eslint-enable */
